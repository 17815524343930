/* eslint-disable import/no-cycle */
import Flex from "app/components/Flex";
import React from "react";
import { useHistory } from "react-router-dom";
import { NavFilterButton } from "app/components/Button/NavFilterButton";
import { MenuOptions } from "./types";
import { useGetMenuOption } from "./hooks";

export const Menu = () => {
  const { selectedOption } = useGetMenuOption();
  const history = useHistory();

  const checkSelectedOption = (option: MenuOptions) =>
    option === selectedOption;

  const handleSelectOption = (option: MenuOptions) => {
    history.push(`/saved/${option}`);
  };

  return (
    <Flex
      alignItems="center"
      maxWidth="624px"
      width="100%"
      flexWrap="wrap"
      gap="8px"
    >
      <NavFilterButton
        text="classes"
        isSelected={checkSelectedOption(MenuOptions.CLASSES)}
        onClick={() => handleSelectOption(MenuOptions.CLASSES)}
      />
      <NavFilterButton
        text="programs"
        isSelected={checkSelectedOption(MenuOptions.PROGRAMS)}
        onClick={() => handleSelectOption(MenuOptions.PROGRAMS)}
      />
    </Flex>
  );
};
